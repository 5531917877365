<template>
    <div class="split-pages-container">
        <a-pagination @change="onChange" :current="pageNo" :pageSize="pageSize" :total="totalPage" />
    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import { Pagination } from 'ant-design-vue';

export default {
  components: {
    'a-pagination': Pagination, // Register the component locally
  },
    props: {
        initData: {
            type: Object,
            required: true
        },
        onPageChanged: {
            type: Function,
            default: () => {}
        },
    },
    data() {
        return {
            totalPage: 0,
            pageSize: 10,
            disablePrev: true,
            disableNext: true,
            listPage: [],
            tmpPageIndex: 0, // 移到 data 中
            pageNo: 1,
        }
    },
    mounted() {
        console.log(this.initData)
        this.pageSize = this.initData.pageSize
        this.totalPage = this.initData.total 
        this.pageNo = this.initData.pageNo || 1
    },
    methods: {
        onChange(page, pageSize) {
            this.pageNo = page
            this.onPageChanged(page)
        },
        initializePagination() {
            this.pageSize = this.initData.pageSize
            this.totalPage = Math.ceil(this.initData.total / this.pageSize)
            let pageNo = this.initData.pageNo || 1

            // 处理路由中的 page 参数
            const hash = this.$route.hash.replace('#', '')
            const list = hash.split('&')
            const key = list[0].split('=')[0]
            const value = parseInt(list[0].split('=')[1], 10)
            if (key === 'page' && !isNaN(value)) {
                pageNo = Math.max(1, Math.min(value, this.totalPage))
            }

            this.refreshPageInfo(pageNo)
            this.updateButtonState(pageNo)
        },
        // 更新上一页和下一页按钮的状态
        updateButtonState(pageNo) {
            this.disablePrev = pageNo === 1
            this.disableNext = pageNo === this.totalPage
        },
        // 生成分页信息，确保第一页和最后一页始终存在
        refreshPageInfo(pageNo) {
            const list = []

            if (this.totalPage <= 7) {
                // 总页数小于等于7，全部显示
                for (let i = 1; i <= this.totalPage; i++) {
                    list.push({
                        id: uuidv4(),
                        pageNo: i,
                        isMore: false,
                        checked: pageNo === i,
                    })
                }
            } else {
                // 始终显示第一页
                list.push({
                    id: uuidv4(),
                    pageNo: 1,
                    isMore: false,
                    checked: pageNo === 1,
                })

                // 判断是否需要左侧省略号
                if (pageNo > 4) {
                    list.push({
                        id: uuidv4(),
                        pageNo: null,
                        isMore: true,
                        checked: false,
                    })
                }

                // 计算中间页码的起始和结束
                const start = Math.max(2, pageNo - 2)
                const end = Math.min(this.totalPage - 1, pageNo + 2)

                for (let i = start; i <= end; i++) {
                    list.push({
                        id: uuidv4(),
                        pageNo: i,
                        isMore: false,
                        checked: pageNo === i,
                    })
                }

                // 判断是否需要右侧省略号
                if (pageNo < this.totalPage - 3) {
                    list.push({
                        id: uuidv4(),
                        pageNo: null,
                        isMore: true,
                        checked: false,
                    })
                }

                // 始终显示最后一页
                list.push({
                    id: uuidv4(),
                    pageNo: this.totalPage,
                    isMore: false,
                    checked: pageNo === this.totalPage,
                })
            }

            this.listPage = list
        },
        toPrev() {
            const currentPage = this.listPage.find(p => p.checked)
            if (currentPage.pageNo > 1) {
                this.jumpToPage({ pageNo: currentPage.pageNo - 1, isMore: false }, false, -1)
            }
        },
        toNext() {
            const currentPage = this.listPage.find(p => p.checked)
            if (currentPage.pageNo < this.totalPage) {
                this.jumpToPage({ pageNo: currentPage.pageNo + 1, isMore: false }, true, -1)
            }
        },
        jumpToPage(page, toNext, index) {
            if (!page) {
                const mPage = this.listPage.find(p => p.checked)
                const newPage = toNext ? mPage.pageNo + 1 : mPage.pageNo - 1
                const validPage = Math.max(1, Math.min(newPage, this.totalPage))
                this.refreshPageInfo(validPage)
                this.updateButtonState(validPage)
                this.onPageChanged(validPage)
                return
            }

            if (page.isMore) {
                // 判断点击的是左边的 "..." 还是右边的 "..."
                const firstMoreIndex = this.listPage.findIndex(p => p.isMore)
                const lastMoreIndex = this.listPage.length - 1 - this.listPage.slice().reverse().findIndex(p => p.isMore)

                if (index === firstMoreIndex) {
                    // 点击的是左边的 "..."，跳转到当前页前5页
                    const mPage = this.listPage.find(p => p.checked)
                    const newPage = Math.max(1, mPage.pageNo - 5)
                    this.refreshPageInfo(newPage)
                    this.updateButtonState(newPage)
                    this.onPageChanged(newPage)
                } else if (index === lastMoreIndex) {
                    // 点击的是右边的 "..."，跳转到当前页后5页
                    const mPage = this.listPage.find(p => p.checked)
                    const newPage = Math.min(this.totalPage, mPage.pageNo + 5)
                    this.refreshPageInfo(newPage)
                    this.updateButtonState(newPage)
                    this.onPageChanged(newPage)
                }
            } else {
                // 点击的是具体的页码
                this.refreshPageInfo(page.pageNo)
                this.updateButtonState(page.pageNo)
                this.onPageChanged(page.pageNo)
            }
        }
    },
    watch: {
        $route(to, from) {
            const hash = to.hash.replace('#', '')
            const list = hash.split('&')
            const key = list[0].split('=')[0]
            const value = parseInt(list[0].split('=')[1], 10)
            let pageNo = 1
            if (key === 'page' && !isNaN(value)) {
                pageNo = Math.max(1, Math.min(value, this.totalPage))
            }
            this.pageNo = pageNo
            this.refreshPageInfo(pageNo)
            this.updateButtonState(pageNo)
        }
    }
}
</script>

<style lang="scss">
.split-pages-container {
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    justify-content: flex-end;
}

.ant-pagination-item-active a,
.ant-pagination-item-active:focus a, .ant-pagination-item-active:hover a {
    color:#36C4D0;
}
.ant-pagination-item:focus, .ant-pagination-item:hover,
.ant-pagination-item-active {
    border-color: #36C4D0;
}
.ant-pagination-prev:focus .ant-pagination-item-link, .ant-pagination-next:focus .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
    border-color: #36C4D0;
    color: #36C4D0;
}

</style>
